.mainPage > div {
    display: flex;
}

.location {
    display: flex;
    justify-content: space-around;
    padding: 2px;
    border: 1px solid #ccc; /* 添加边框 */
    border-radius: 10px; /* 添加圆角 */
    background-color: #f9f9f9; /* 设置背景颜色 */
    margin: 10px;
    font-size: 12px;
}

.expectLocation {
    display: flex;
    justify-content: space-around;
    padding: 2px; /* 设置内边距 */
    border: 1px solid #ccc; /* 添加边框 */
    border-radius: 10px; /* 添加圆角 */
    background-color: #EBE0D4; /* 设置背景颜色 */
    margin: 10px;
    font-size: 12px;
}

.location > div,
.expectLocation > div,
.allBus > div {
    margin: 10px;
}

.location h3,
.expectLocation h3 {
    font-size: 12px;
    width: 18%;
}

.location > div > div,
.expectLocation > div > div {
    margin-left: 12px; 
}

.carName {
    font-weight: 800;
    color: #8a2be2;
}

.allBus {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 5px;
    border: 1px solid #ccc; /* 添加边框 */
    border-radius: 10px; /* 添加圆角 */
    background-color: #f9f9f9; /* 设置背景颜色 */
    margin: 10px;
    font-size: 12px;
}

.allBus h3  {
    font-size: 20px;
    width: 30%;
}