.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 1.2rem 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.main a {
    color: #1796577e;
    text-decoration: none;
    margin: 0 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    position: relative;
    transition: color 0.3s ease;
    margin: 20px;

}

.main a:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffc107;
    transition: width 0.3s ease;
}

.main a:hover {
    color: #ffc107;
}

.main a:hover:before {
    width: 100%;
}

.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}