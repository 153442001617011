.navBar {
    background-color: #f0e6f6;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.navBar a {
    margin: 0 15px;
    text-decoration: none;
    color: #8a2be2;
    font-weight: bold;
}

.navBar a:hover {
    color: #da70d6;
}

.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}


